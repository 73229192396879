//for test
import _ from "lodash";

let collapseBtn = document.querySelectorAll('.event-list-item--descr-trigger');
for(let i = 0; i < collapseBtn.length; i++){
    collapseBtn[i].addEventListener('click', function (e){
        //e.preventDefault();
        let getID = e.currentTarget.dataset.target + '';
        let toggBlock = document.getElementById(getID);
        let contentWrapper = e.currentTarget.closest('.event-list-item--descr-wrapper');
        contentWrapper.classList.toggle('--full-descr');
        toggBlock.classList.toggle("collapsed");
    });
}


let tabItemLink = document.querySelectorAll('.tab-nav--item .tab-nav--link');
let activeTAb = document.getElementsByClassName('tab-active');
const toggleContent = function() {
    if (!this.classList.contains("tab-active")) {
        Array.from(activeTAb).forEach( item => {
            item.classList.remove('tab-active');
        });

        this.classList.add('tab-active');

        let currentTab = this.getAttribute('data-tab'),
            _tabContent = document.getElementById(currentTab);
            _tabContent.classList.add('tab-active');
    }
};


Array.from(tabItemLink).forEach( item => {
    item.addEventListener('click', toggleContent);
});

document.addEventListener('DOMContentLoaded', function(event){
    function inIframe () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    function autoSctollToCalendarBlock(){
        setTimeout(function () {
            let toSection = document.querySelector(".back-button__wrapper");
            if(toSection){
                //console.log('function autoSctollToCalendarBlock() ', 'toSection.offsetTop = ', toSection.offsetTop);
                toSection.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }, 600);
    }

    if(!inIframe()){
        autoSctollToCalendarBlock();
    }
});

var customTheme = null;

window.addEventListener("message", function (event) { // for preview in admin interface only
    if (event.data && event.data.event) {
        switch (event.data.event) {
            case 'changeAppearance':
                if (event.data.ada) {
                    $('body').addClass('--ada-styles');
                    customTheme = {
                        'header_background': '#fff',
                        'line_color': '#ddd',
                        'welcome_message_text_color': '#000',
                        'header_username_text_color': '#111',
                        'header_color': '#222',
                        'background': '#fff',
                        'text_color': '#000',
                        'link_color': '#0c1ccc',
                        'button_color': '#0c1ccc',
                        'button_text_color': '#fff',
                        'tile_background': '#cfe5ff',
                        'tile_text_color': '#0c1ccc'
                    };
                } else {
                    $('body').removeClass('--ada-styles');
                    customTheme = event.data.colors;
                }
                _.each(customTheme, (color, key) => {
                    document.documentElement.style.setProperty('--' + key, color);
                });
                $('#custom-css').text(event.data.customCss);
                $('.booking-page__powered-by-sb').toggle(event.data.brandingEnabled);
            break;
        }
    }
}, false);

